import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "monitor"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/monitor.png"}) { ...eyecatchImg },
    fullHD: file(relativePath: { eq: "monitor/fullHD.png"}) { ...normalImg },
    scale: file(relativePath: { eq: "monitor/scale.png"}) { ...normalImg },
    output: file(relativePath: { eq: "monitor/output.PNG"}) { ...normalImg },
    work: file(relativePath: { eq: "monitor/work.png"}) { ...normalImg },
    watch: file(relativePath: { eq: "monitor/watch.png"}) { ...normalImg },
    game: file(relativePath: { eq: "monitor/game.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`液晶モニター（液晶ディスプレイ）の選び方`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="液晶モニターの選び方" mdxType="Image" />
    <p>{`パソコン用の液晶モニターは各社から多数発売されているものの、一体何を基準に選べば良いのか、どのモニターがどの作業に適しているのかなどよくわからないと思われる。本稿では目的に合わせた最適なモニター選びを説明する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "モニター機能早見表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E6%A9%9F%E8%83%BD%E6%97%A9%E8%A6%8B%E8%A1%A8",
        "aria-label": "モニター機能早見表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`モニター機能早見表`}</h2>
    <p>{`パソコンモニターには複数の機能が備わっており、画面を見るだけのものにしては複雑で分かりにくい。そこでどのような特徴があり、`}<strong parentName="p">{`目的別に何を見れば良いのかを整理した。`}</strong>{`それが下の表である。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th></th>
      <th>特徴</th>
      <th>備考</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td rowSpan="5">共通</td>
      <td>解像度</td>
      <td>液晶モニタのドットの数</td>
    </tr>
    <tr>
      <td>イヤホンジャック</td>
      <td>ヘッドホンに繋ぐことができる</td>
    </tr>
    <tr>
      <td>音声出力</td>
      <td>あるとスピーカー不要。音質はほどほど。</td>
    </tr>
    <tr>
      <td>入力端子</td>
      <td>端子の種類、バージョンにより解像度とFPSが異なる</td>
    </tr>
    <tr>
      <td>USB端子</td>
      <td>電力供給、映像出力等いろいろ使える</td>
    </tr>
    <tr>
      <td rowSpan="6">作業用</td>
      <td>ノングレアタイプ(非光沢)</td>
      <td>ギラギラしてなくて目に優しい</td>
    </tr>
    <tr>
      <td>高さ調整機能</td>
      <td>作業中に正しい姿勢へ</td>
    </tr>
    <tr>
      <td>チルト角(傾き)調整機能</td>
      <td>パソコンの椅子でだらっとした時に便利</td>
    </tr>
    <tr>
      <td>ブルーライトカットモード</td>
      <td>色を自分で調整しなくても良い</td>
    </tr>
    <tr>
      <td>IPSパネル</td>
      <td>色が安定して目に優しい？といわれているが不明</td>
    </tr>
    <tr>
      <td>ウルトラワイドモニター</td>
      <td>作業領域を広くとることができ生産性が向上</td>
    </tr>
    <tr>
      <td rowSpan="5">映像用</td>
      <td>高解像度(4K/WQHD)</td>
      <td>ドットが細かく映像や画像が美しく</td>
    </tr>
    <tr>
      <td>グレアモニタ</td>
      <td>光沢のあるモニタで映像が映えるが絶滅気味</td>
    </tr>
    <tr>
      <td>ベゼル（縁）が狭い</td>
      <td>臨場感が出て映像に迫力が出る</td>
    </tr>
    <tr>
      <td>高画質技術</td>
      <td>HDR、超解像技術、独自の描画エンジンなど</td>
    </tr>
    <tr>
      <td>視野角広め</td>
      <td>一人で見るときは正面から見るので無関係</td>
    </tr>
    <tr>
      <td rowSpan="3">ゲーム用</td>
      <td>144Hz(fps)対応</td>
      <td>映像の滑らかさ、通常は60fps、２倍以上の描画性能</td>
    </tr>
    <tr>
      <td>応答速度1ms以下</td>
      <td>ボタンを押してからの反応の速さ。1msだとほぼ遅延なし</td>
    </tr>
    <tr>
      <td>湾曲ディスプレイ</td>
      <td>自分を囲うようにモニタが湾曲し没入感がUp</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`以下ではそれぞれの項目に対して簡単に述べていき、最後におすすめのモニターを紹介しようと思う。`}</p>
    <h2 {...{
      "id": "共通項目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%85%B1%E9%80%9A%E9%A0%85%E7%9B%AE",
        "aria-label": "共通項目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`共通項目`}</h2>
    <p>{`まずはパソコン作業用、映像鑑賞用、ゲーム用のすべてのモニターを選ぶときに必要な項目から確認する。`}</p>
    <h3 {...{
      "id": "最適な解像度はどれくらい？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%80%E9%81%A9%E3%81%AA%E8%A7%A3%E5%83%8F%E5%BA%A6%E3%81%AF%E3%81%A9%E3%82%8C%E3%81%8F%E3%82%89%E3%81%84%EF%BC%9F",
        "aria-label": "最適な解像度はどれくらい？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`最適な解像度はどれくらい？`}</h3>
    <Image {...props} name="fullHD" alt="FullHD液晶モニター" mdxType="Image" />
    <p>{`まず解像度とは画面を表示するためのドット（画像を表示するための細かな点)が横、縦それぞれで何個あるかを示す値である。この点が集まって画面は構成されている。`}</p>
    <p>{`点が多いと情報を表示でき、例えば縦解像度が高いとWebサイトをスクロールしなくとも多くの情報を表示することができる。`}</p>
    <p>{`また`}<strong parentName="p"><em parentName="strong">{`解像度が高いほど高精細で、動画も綺麗に表示することが可能`}</em></strong>{`だ。`}</p>
    <p>{`デスクトップ用のモニターの現在の標準的なサイズはFullHDと呼ばれる解像度で横1920ドット、縦1080ドットである。23から4インチでFullHDが主流となっている。`}</p>
    <p>{`誤解されがちなのだが、`}<strong parentName="p">{`解像度は単純にドットの数であり、実際の作業領域の広さとは必ずしも一致しない。`}<em parentName="strong">{`高解像度モニターで表示をスケールアップさせて高画質化すること`}</em>{`も可能`}</strong>{`である。`}</p>
    <Image {...props} name="scale" caption="高解像度モニターで精細さを上げる" mdxType="Image" />
    <p>{`図はFullHDを1.5倍拡大で表示したスクリーンとHDスクリーンを比較している。表示される大きさは同じだが、`}<strong parentName="p">{`FullHDを拡大させたものの方が鮮明になっている`}</strong>{`ことが分かると思う。(画像をクリックして拡大すると分かりやすいかも)`}</p>
    <p>{`用途にもよるが`}<strong parentName="p">{`13.3インチ~24インチまではFullHD(1920x1080)、27インチ以上30インチ未満は一回り解像度が高いWQHD(2560x1440)、それ以上は4K(3920x2160)がコストパフォーマンスの観点でおすすめ`}</strong>{`できる。`}</p>
    <p>{`大は小を兼ねるので、値段が同じならば当然解像度が高い方が良い。`}</p>
    <h3 {...{
      "id": "サウンド機能の有無に注意",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B5%E3%82%A6%E3%83%B3%E3%83%89%E6%A9%9F%E8%83%BD%E3%81%AE%E6%9C%89%E7%84%A1%E3%81%AB%E6%B3%A8%E6%84%8F",
        "aria-label": "サウンド機能の有無に注意 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`サウンド機能の有無に注意`}</h3>
    <p>{`デスクトップパソコンの本体を購入しただけではまだ音が出ない。音を出すには別途スピーカーを購入するか、サウンド機能付属のモニターを購入する必要がある。`}</p>
    <p>{`液晶モニターは薄いので、厚みのある音を出すには向かない。よって`}<strong parentName="p">{`音質は決して悪くないもののほどほど`}</strong>{`であるが、スピーカーを別途購入する必要もないので費用の面と、スペースの面で有利である。`}</p>
    <p>{`またサウンド機能に対応していなくても、音声出力端子(イヤホンジャック)は付いている場合もある。この場合、パソコン本体からではなく、モニターへ有線ヘッドホンやヘッドセットを挿して使えるため便利。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`イヤホンジャックと音声出力の有無は見落としやすいポイント`}</em></strong>{`なので気を付けよう。`}</p>
    <h3 {...{
      "id": "映像出力端子を確認",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%98%A0%E5%83%8F%E5%87%BA%E5%8A%9B%E7%AB%AF%E5%AD%90%E3%82%92%E7%A2%BA%E8%AA%8D",
        "aria-label": "映像出力端子を確認 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`映像出力端子を確認`}</h3>
    <Image {...props} name="output" alt="入出力端子" mdxType="Image" />
    <p>{`端子は`}<em parentName="p">{`HDMI`}</em>{`、`}<em parentName="p">{`DisplayPort`}</em>{`、`}<em parentName="p">{`USB Type-C`}</em>{`などの種類が対応しているかを確認する必要がある。対応する端子のパソコンを持っていない場合、変換ケーブルを使えば解決する場合も多いが、何かとトラブルが発生するケースもあるのでおすすめしない。`}</p>
    <p>{`また通常のPCモニターを使う上ではあまり問題とならないが、例えば`}<strong parentName="p"><em parentName="strong">{`8Kなど高解像度の映像出力や、120Hzなど高いリフレッシュレート(1秒あたりの描画映像数)を実現しようとすると、それぞれの規格のVersionも確認する必要がある`}</em></strong>{`。例を挙げると次の通り。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>映像出力端子</th>
      <th>特徴</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>HDMI</td>
      <td>Ver1.4から4K対応、2.0で4K 60Hz対応、2.1で8Kまで対応</td>
    </tr>
    <tr>
      <td>Display Port</td>
      <td>Ver1.2以上で4K 60Hz対応。2.0では16K解像度まで対応。HDMIと同様音声信号も送られる。</td>
    </tr>
    <tr>
      <td>USB Type-C</td>
      <td>Altモードにて映像出力に対応していればDisplayPort Ver1.4相当の映像出力が可能。</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`まとめると、それぞれの規格は、使用するデバイスやケーブル、モニターによって最大性能が発揮されるため、パソコン側、モニター側ともに対応規格の確認作業が必要となる。`}</p>
    <h3 {...{
      "id": "USB端子",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB%E7%AB%AF%E5%AD%90",
        "aria-label": "USB端子 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB端子`}</h3>
    <p>{`USBポートがついているモニターがある。`}<strong parentName="p">{`USB Type-C経由でパソコンとモニタ－を接続すると、モニター自体がUSBハブの役割を果たし、パソコンと同様にUSBメモリやキーボード接続に使うことができる`}</strong>{`。`}</p>
    <p>{`一方でHDMIなど他の接続をした場合で、も別途USB用の接続端子ケーブル(基本的には製品に付属)を繋げばUSBを使えるようになるため心配は不要である。`}</p>
    <p>{`ただ、USB端子は安価なモニターからは省かれるため、比較的高価なモニターを購入する必要がある。`}</p>
    <p>{`特に`}<strong parentName="p"><em parentName="strong">{`Type-C接続はノートパソコンとモニターを接続するときに非常に便利であり、給電と映像出力とUSBハブの仲介をケーブル一本で担うことができる`}</em></strong>{`。もはやノートパソコンの専用電源は不要とである。`}</p>
    <SmallBox type="link" mdxType="SmallBox">
  <Link to="/usb_type-c/" mdxType="Link">USB Type-Cとは何か？</Link>
    </SmallBox>
    <h2 {...{
      "id": "作業用モニター項目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BD%9C%E6%A5%AD%E7%94%A8%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E9%A0%85%E7%9B%AE",
        "aria-label": "作業用モニター項目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`作業用モニター項目`}</h2>
    <Image {...props} name="work" alt="作業に最適なモニター" mdxType="Image" />
    <p>{`パソコンで作業をする時、テレビと比べて集中してモニターを見る必要がある。また、モニターとの距離も近く疲れやすい。`}</p>
    <p>{`そこで`}<strong parentName="p"><em parentName="strong">{`いかに目に優しいモニターを楽な体勢で見ることができるか`}</em></strong>{`ということが選びの基本となってくる。その項目について説明する。`}</p>
    <h3 {...{
      "id": "ノングレアタイプの液晶モニターを選ぶ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%B3%E3%82%B0%E3%83%AC%E3%82%A2%E3%82%BF%E3%82%A4%E3%83%97%E3%81%AE%E6%B6%B2%E6%99%B6%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E3%82%92%E9%81%B8%E3%81%B6",
        "aria-label": "ノングレアタイプの液晶モニターを選ぶ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノングレアタイプの液晶モニターを選ぶ`}</h3>
    <p>{`まずモニタには`}<em parentName="p">{`グレアタイプ`}</em>{`と`}<em parentName="p">{`ノングレアタイプ`}</em>{`という２つのタイプが存在する。グレアタイプは液晶が光を反射しやすい素材でできているもので、ノングレアタイプは逆に光を反射しにくいタイプである。`}</p>
    <p>{`ノングレアのものはチラつきが少なく、作業をするには適しているタイプだといえる。ただし映像を見るには見栄えがあまりしない。`}</p>
    <h3 {...{
      "id": "高さ、傾きの調整機能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%AB%98%E3%81%95%E3%80%81%E5%82%BE%E3%81%8D%E3%81%AE%E8%AA%BF%E6%95%B4%E6%A9%9F%E8%83%BD",
        "aria-label": "高さ、傾きの調整機能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`高さ、傾きの調整機能`}</h3>
    <p>{`パソコンの位置は、目線が少し下にくるぐらいがちょうど良いとされている。このため疲れにくい高さに調節できるように高さ調整機能が必要となる。この機能があるタイプとないタイプはちょうど半々程度である。`}</p>
    <p>{`また長時間同じ姿勢をとることはしんどいので、たまに椅子にもたれかかって、だらっとした体勢でパソコン作業を行いたい時がある。そのときにモニターの傾きを調整できる機能(チルト角調整機能)があれば便利だ。`}</p>
    <h3 {...{
      "id": "ブルーライトカットモード",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%96%E3%83%AB%E3%83%BC%E3%83%A9%E3%82%A4%E3%83%88%E3%82%AB%E3%83%83%E3%83%88%E3%83%A2%E3%83%BC%E3%83%89",
        "aria-label": "ブルーライトカットモード permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ブルーライトカットモード`}</h3>
    <p>{`ブルーライトをカットする画質モードを新たに搭載した。などと書かれているが、これは単にパソコンモニターのRGBの設定値をいじっただけであり、自分でも簡単に調整することができる。`}</p>
    <p>{`RGBのBはBlueであるが、`}<strong parentName="p">{`この値を0近くまで落としてやれば全ブルーライトカット`}</strong>{`というわけである。もっともかなりモニターが黄色くなるため、ある程度はブルーライトを残すように調整はするが。`}</p>
    <p>{`ボタン一発で調整できますよ、というのがブルーライトカットモードである。よって`}<strong parentName="p"><em parentName="strong">{`便利といえば便利だが、売りとなる機能でもない`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "IPSパネル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#IPS%E3%83%91%E3%83%8D%E3%83%AB",
        "aria-label": "IPSパネル permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IPSパネル`}</h3>
    <p>{`色調の変化が少なく、色合いに滲みが少ないのがIPSの特徴で、それにより目も疲れにくくなるとされている。ただし実際IPSだから疲れにくいと感じたことはない。あまり気にしなくても良いと思う。`}</p>
    <p>{`他にはTNパネル,VAパネルという種類がある。一般的にTNパネルは応答速度が速いが視野角が狭い、VAはコントラストが高いという性質があるが、`}<strong parentName="p"><em parentName="strong">{`個人的にはその差異を感じたことはない`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "デュアルディスプレイの検討",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%83%A5%E3%82%A2%E3%83%AB%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E3%81%AE%E6%A4%9C%E8%A8%8E",
        "aria-label": "デュアルディスプレイの検討 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デュアルディスプレイの検討`}</h3>
    <p>{`Excelを開いたまま、インターネットで調べ物をするなど複数を同時にこなすシーンは多い。このため`}<strong parentName="p"><em parentName="strong">{`画面領域を拡張し２台のモニターに表示するデュアルディスプレイ環境があれば作業効率が大きく向上`}</em></strong>{`する。`}</p>
    <p>{`27インチのモニター２台は大きすぎるため、`}<strong parentName="p"><em parentName="strong">{`モニターサイズは23インチ程度が望ましい`}</em></strong>{`。23インチのモニターを購入しておき、１台のモニターに物足りなさを感じたらもう一台同じものを追加するのも良い。`}</p>
    <p>{`モニタにより色合いはベゼルの幅などが異なるため、違和感を覚えないためにも`}<strong parentName="p"><em parentName="strong">{`モニタはできれば同じものを２つ使いたい`}</em></strong>{`ところである。`}</p>
    <p>{`トリプルディスプレイ以上を目指す場合はグラフィックボードやグラフィックアダプターといった製品を別途購入する必要があるが、デュアルならば大抵どのパソコンでも可能である。`}</p>
    <h3 {...{
      "id": "ウルトラワイドモニターも検討の余地あり",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%A6%E3%83%AB%E3%83%88%E3%83%A9%E3%83%AF%E3%82%A4%E3%83%89%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E3%82%82%E6%A4%9C%E8%A8%8E%E3%81%AE%E4%BD%99%E5%9C%B0%E3%81%82%E3%82%8A",
        "aria-label": "ウルトラワイドモニターも検討の余地あり permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ウルトラワイドモニターも検討の余地あり`}</h3>
    <p>{`個人的にはデュアルディスプレイを検討しているならば、解像度が3400x1440(`}<em parentName="p">{`UWQHD`}</em>{`)のウルトラワイドモニターをおすすめしたい。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`デュアルディスプレイの最大の欠点は常に横を向いての作業を強いられるため姿勢が悪くなる`}</em></strong>{`点である。個人的には軽視していたが、首を痛めたためウルトラワイドモニターに切り替えた経緯がある。`}</p>
    <p>{`複数のアプリが立ち上がっている時、画面中のWindowはWindowsボタンと方向キーで簡単に切り替えられ、デュアルモニターと比較して特段の不自由は感じないし、まっすぐな姿勢で作業ができる。`}<strong parentName="p"><em parentName="strong">{`サイズ的には34インチがちょうどしっくりくる。`}</em></strong></p>
    <p>{`また`}<em parentName="p">{`曲面モニター`}</em>{`にするのもおすすめである。曲面にすることにより、ディスプレイの中央を見るときと端を見るときで距離があまり変わらず、`}<strong parentName="p"><em parentName="strong">{`目の焦点距離が変わらないことにより眼精疲労を抑えることができる`}</em></strong>{`ためである。`}</p>
    <h3 {...{
      "id": "おすすめ作業用モニター",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E4%BD%9C%E6%A5%AD%E7%94%A8%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC",
        "aria-label": "おすすめ作業用モニター permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめ作業用モニター`}</h3>
    <p>{`モニターは多くのメーカーから毎年新製品が発売されており、種類が多いため、検索条件からコストパフォーマンスに優れたモデルを選ぶのが良いだろう。`}</p>
    <AccentBox title="おすすめの作業用モニター" mdxType="AccentBox">
  <li><span className="bold">コストを抑えたいならば</span>・・・<a href="https://amzn.to/4hno6ki" target="_blank" rel="nofollow noopener">非光沢 フルHD(1920x1080)モニター</a></li>
  <li><span className="bold">生産性を追求したいならば</span>・・・<a href="https://amzn.to/48gcZoR" target="_blank" rel="nofollow noopener">非光沢 曲面UWQHD(3400x1440)ウルトラワイドモニター </a></li>
    </AccentBox>
    <p>{`これらから高さ調整ありや音声出力あり、Type-Cの有無などを確認して絞っていくと良い。`}</p>
    <h2 {...{
      "id": "映像用モニター項目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%98%A0%E5%83%8F%E7%94%A8%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E9%A0%85%E7%9B%AE",
        "aria-label": "映像用モニター項目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`映像用モニター項目`}</h2>
    <Image {...props} name="watch" alt="映像視聴に最適なモニター" mdxType="Image" />
    <p>{`パソコンで動画や写真を見るときにできるだけ綺麗に表示したい。また如何に迫力のある映像を楽しめるかということもポイントとなる。`}</p>
    <p>{`人によっては台所からリビングの映像を見るなど、斜めから動画を見たいケースもあるだろう。そのための項目をここでは述べる。`}</p>
    <h3 {...{
      "id": "高解像度大画面",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%AB%98%E8%A7%A3%E5%83%8F%E5%BA%A6%E5%A4%A7%E7%94%BB%E9%9D%A2",
        "aria-label": "高解像度大画面 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`高解像度大画面`}</h3>
    <p>{`迫力のある美しい映像を楽しもうとすると、ディスプレイは大きい方が良い。`}</p>
    <p>{`パソコンを作業用途でも使うことを考えると`}<strong parentName="p"><em parentName="strong">{`27インチ～32インチ程度が両立を図るうえではおすすめ`}</em></strong>{`できる。私は昔43インチのモニターを作業用に使っていたが流石にデカすぎた。`}</p>
    <p>{`27インチのFullHDは粗が目立つというほどでもないが、動画や静止画を高精細で楽しもうとすると解像度は高い方が良い。`}</p>
    <p>{`解像度の高い画像を縮小せずに表示したり、Youtubeなどで増えてきている高解像度の動画をそのままの画質で楽しもうと考えた場合、`}<strong parentName="p"><em parentName="strong">{`4K(3840x1920)が欲しいところ`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "グレアタイプは色合いが綺麗",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B0%E3%83%AC%E3%82%A2%E3%82%BF%E3%82%A4%E3%83%97%E3%81%AF%E8%89%B2%E5%90%88%E3%81%84%E3%81%8C%E7%B6%BA%E9%BA%97",
        "aria-label": "グレアタイプは色合いが綺麗 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`グレアタイプは色合いが綺麗`}</h3>
    <p>{`グレアタイプは一般的に明るく見栄えがするため動画の視聴をするのには適したタイプである。一方で画面が光の反射でちらついてしまい、インターネットで長時間文字を読むことについては向いていない。`}</p>
    <p>{`映像と静止画の閲覧が7で作業3ぐらいの割合からグレアタイプを選択しても良いと思う。`}</p>
    <p>{`もっともグレアタイプの液晶は映像専用モニターとしてパソコンのセットで付属している以外ほとんど見ない絶滅危惧種である。`}</p>
    <h3 {...{
      "id": "ベゼルが狭いと臨場感が増す",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%99%E3%82%BC%E3%83%AB%E3%81%8C%E7%8B%AD%E3%81%84%E3%81%A8%E8%87%A8%E5%A0%B4%E6%84%9F%E3%81%8C%E5%A2%97%E3%81%99",
        "aria-label": "ベゼルが狭いと臨場感が増す permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ベゼルが狭いと臨場感が増す`}</h3>
    <p>{`ベゼルとはパソコンの縁のことだが、これがあるがためにどうしてもモニターの中で映像が再生されているという意識が生まれるのか、映像視聴中に臨場感が消えてしまうことがある。`}</p>
    <p>{`ベゼルが狭いとそのモニターのサイズ以上の迫力を味わうことができる。`}</p>
    <h3 {...{
      "id": "高画質技術HDR超解像",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%AB%98%E7%94%BB%E8%B3%AA%E6%8A%80%E8%A1%93HDR%E8%B6%85%E8%A7%A3%E5%83%8F",
        "aria-label": "高画質技術HDR超解像 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`高画質技術(HDR/超解像)`}</h3>
    <p>{`映画をネット動画で視聴中に、画面が暗くて詳細が良く分からないという経験をしたことはないだろうか？`}</p>
    <p><em parentName="p">{`HDR(ハイダイナミックレンジ)`}</em>{`という技術は明るい所はしっかり明るく、暗い所はしっかり暗くすることで`}<strong parentName="p">{`コントラストを目立たせ細部までしっかりと描画する技術`}</strong>{`である。動画視聴には重要な機能といえるだろう。`}</p>
    <p>{`ちなみにコントラストは`}<em parentName="p">{`コントラスト比`}</em>{`と呼ばれるディスプレイの中で一番明るいところと暗いところで明るさの差が何倍違うかという指標が使われる。各社1000:1程度でおおよそ横並びになっているが、`}<strong parentName="p"><em parentName="strong">{`3000:1などのハイコントラストなモニターも存在し、映像のクオリティが一段上がる`}</em></strong>{`。`}</p>
    <p><em parentName="p">{`超解像技術`}</em>{`が使われている液晶モニターは確かに写真が綺麗に写る。`}<a parentName="p" {...{
        "href": "https://amzn.to/3lG79EX",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`EIZOのFlexScan`}</a>{`は超解像度の強さを調整できるのでそれを0からいじってみると、はっきり分かるレベルで綺麗になる。動画に対しても多少効力を発揮する。`}</p>
    <p>{`写真が趣味で、静止画をより綺麗に閲覧したいならばFlexScanはおすすめできる。`}</p>
    <h3 {...{
      "id": "視野角が広め",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%A6%96%E9%87%8E%E8%A7%92%E3%81%8C%E5%BA%83%E3%82%81",
        "aria-label": "視野角が広め permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`視野角が広め`}</h3>
    <p>{`視野角が広いと、斜めの角度から映像を見てもはっきりと見ることができる。複数人で映像を見るときには視野角が広い方が良い。ただ、パソコン画面をあまり見られたくない場合は逆に狭いものを選ぶということもいえる。上下左右178°が見た中ではもっとも高い視野角であった。`}</p>
    <p>{`視野角は下で説明するコントラストが10:1になるまでの角度のことであり、`}<strong parentName="p">{`視野角が178°だからといって横からも前からと同じように見えるわけではない`}</strong>{`。170°と178°では横から見た時やはり178°の方が綺麗にうつることが確認できる。`}</p>
    <h3 {...{
      "id": "おすすめ映像用モニター",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E6%98%A0%E5%83%8F%E7%94%A8%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC",
        "aria-label": "おすすめ映像用モニター permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめ映像用モニター`}</h3>
    <p><a parentName="p" {...{
        "href": "https://amzn.to/4h98eSh",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`BenQのエンターテインメントモニター`}</a>{`が特におすすめ。高音質スピーカーも内蔵されていてHDR対応。まさに`}<strong parentName="p"><em parentName="strong">{`ゲームはしないが映像を視聴するという人にとって考え抜かれたモニター`}</em></strong>{`といえる。`}</p>
    <h2 {...{
      "id": "ゲーム用モニター項目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E7%94%A8%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E9%A0%85%E7%9B%AE",
        "aria-label": "ゲーム用モニター項目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲーム用モニター項目`}</h2>
    <Image {...props} name="game" alt="ゲームに最適なモニター" mdxType="Image" />
    <p>{`ゲーミングモニターと題して複数種類が発売されているが、価格は通常のモニターと比べて割高である。通常のモニターと比べどのような違いがあるのだろうか？`}</p>
    <p><strong parentName="p"><em parentName="strong">{`144Hz`}</em>{`と`}<em parentName="strong">{`1ms応答`}</em></strong>{`が滑らかな映像と快適なプレイができるためのキーワードとなる。`}</p>
    <h3 {...{
      "id": "144HzFPS対応",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#144HzFPS%E5%AF%BE%E5%BF%9C",
        "aria-label": "144HzFPS対応 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`144Hz(FPS)対応`}</h3>
    <p>{`1秒間に何枚の絵を描画できるかがHz(FPS)である。144Hzならば144枚の絵を描いている。`}</p>
    <p>{`通常のモニターは60Hzなので、2倍以上滑らかになるということである。実際見るとちょっと滑らかになったかなという印象であるが、確かに比較動画を調べてみるとはっきりと分かる。`}</p>
    <p>{`ただ、60Hzから144Hzにしたときよりも、144Hzから60Hzに落とした時の方が落差を感じるため困りものである。`}</p>
    <p>{`注意しておきたいが、144Hzに対応させるためには高機能なグラフィックボードを積んだゲーム用PCが必要となる。モニターを買うだけでは60FPSしかやはり出すことができない。`}</p>
    <h3 {...{
      "id": "応答速度が速いものが良い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%BF%9C%E7%AD%94%E9%80%9F%E5%BA%A6%E3%81%8C%E9%80%9F%E3%81%84%E3%82%82%E3%81%AE%E3%81%8C%E8%89%AF%E3%81%84",
        "aria-label": "応答速度が速いものが良い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`応答速度が速いものが良い`}</h3>
    <p>{`1ms応答とは、映像出力要求を受けてから実際に要求通りにその絵が出力されるまでの時間が1msという意味である。通常のモニターであると速いもので5msといったところ。`}</p>
    <p>{`これはFPSや格闘ゲームなど`}<strong parentName="p"><em parentName="strong">{`シビアなタイミングが求められるゲームの場合に必要`}</em></strong>{`でかつ、応答が速いと残像も少なく目も疲れにくく感じる。プロゲーマーでもない限りそこまで必要でないとも思われるが、モニターのせいで負けたと思わないためには精神上必要な気もする。`}</p>
    <h3 {...{
      "id": "湾曲モニター",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%B9%BE%E6%9B%B2%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC",
        "aria-label": "湾曲モニター permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`湾曲モニター`}</h3>
    <p>{`自分を囲うように湾曲しているモニターのことを言う。映像は自分からの距離が近く感じ、音も自分を目掛けてやってくる感覚を覚えるなど`}<strong parentName="p"><em parentName="strong">{`没入感が高く一人でゲームを行うには最適なモニター`}</em></strong>{`といえる。`}</p>
    <p>{`一方で転がりながらや複数人での映像視聴には向いていないので注意が必要である。また、24インチ程度の小さいディスプレイでの湾曲はそこまでメリットを感じないという人も多い。より臨場感を得るには27インチは欲しいところ。`}</p>
    <h3 {...{
      "id": "おすすめゲーミングモニター",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC",
        "aria-label": "おすすめゲーミングモニター permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめゲーミングモニター`}</h3>
    <p>{`正直好みも相当分かれるので、`}<a parentName="p" {...{
        "href": "https://amzn.to/4heMIvc",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ゲーミングモニターをおすすめ順`}</a>{`で確認して、自身に適したものを選べばよい。`}</p>
    <p>{`ただし、注意点としてモニターの性能を生かせるだけのグラフィックボードの性能をパソコンが持っていることが重要なので、`}<Link to='/gpu/' mdxType="Link">{`GPU性能比較表`}</Link>{`でどれほどのリフレッシュレートが期待できるのかを確認しておくと良いだろう。`}</p>
    <h2 {...{
      "id": "まとめ：どのモニターを購入すべきか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81%EF%BC%9A%E3%81%A9%E3%81%AE%E3%83%A2%E3%83%8B%E3%82%BF%E3%83%BC%E3%82%92%E8%B3%BC%E5%85%A5%E3%81%99%E3%81%B9%E3%81%8D%E3%81%8B%EF%BC%9F",
        "aria-label": "まとめ：どのモニターを購入すべきか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ：どのモニターを購入すべきか？`}</h2>
    <p>{`作業用、映像用、ゲーム用とそれぞれモニターに求められる特徴は変わってくることが分かったと思う。`}</p>
    <p>{`しかしパソコンの用途は一つでないため購入の決定は難しいかもしれないが、作業なのか、映像なのか、ゲームなのか、何を重視すべきかをコストも合わせて考えれば結論に行きつくはずである。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      